$(document).ready(function () {

	//Wrap navigation to more dropdown link
	$(".nav-sec").show();
	$(".nav-sec ul.nav-list").navwrap();

	if ($(".propholder").length) {
		$(".propholder").each(function () {
			$(this).height($(this).outerHeight());
		});
	}

	//Featured Listings Carousel
	if($(".featured_listings").length > 0) {
		$(".featured_listings").owlCarousel({
			nav: true,
			responsive: {
				1024: {
					items: 3,
				},
				850: {
					items: 2,
				},
				0: {
					items: 1,
					center: true,
				},
			},
		});
	}

	if ($(".block-right").length > 0) {
		$(".block-right .testimonial-list-container").owlCarousel({
			nav: true,
			dots: false,
			items: 1,
			navContainer: ".carousel-nav",
			responsiveClass:true,
			responsive:{
				0:{
					items:1,
				}
			}
		});
	}

});
